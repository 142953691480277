@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .tanda-border {
    border-right-color: transparent !important;
  }
  .btn-danger {
    @apply shadow-sm text-white bg-tandaRed hover:bg-red-700;
  }
    .btn-primary {
    @apply inline-flex items-center border border-transparent rounded shadow-sm text-white bg-tandaPurple;
  }

  .tanda-remove-input-ring input {
    --tw-ring-color: none !important;
  }
}
.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

@layer utilities {
  .h-470 {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
  }

  .img {
    justify-content: center;
    align-items: center;
    min-width: 100%;
    max-height: 400px;
  }
  .center-items {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
  .center-contents {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .action-buttons {
    padding: 5px 0;
    min-width: 100px;
    height: 30px;
    border-radius: 15px;
}
}
